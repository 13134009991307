import React from 'react'
import './Navbar.css'
import logo from '../../assets/logo.png'
import {Link} from 'react-scroll'
import btnImg from '../../assets/contact.png'
const Navbar = () => {
  return (
    <nav className='navbar'>
      <img src={logo} className='logo'/>
      <div className='menu'>
        <Link className='menuItem'>home</Link>
        <Link className='menuItem'>about</Link>
        <Link className='menuItem'>skills</Link>
        <Link className='menuItem'>experience</Link>
        <Link className='menuItem'>projects</Link>
        <Link className='menuItem'>activities</Link>
      </div>
      <button className='contactBtn'> contact </button>
    </nav>
  )
}

export default Navbar;
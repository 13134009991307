import React from 'react'
import './Aboutme.css'
import resume from '../../assets/Resume.pdf'

const Aboutme = () => {
  return (
     <div class="about me">
          <div class="row">			
                <div class="left">
                    <ul class='info-list' >
                        <li>
                            <strong>Name:</strong>
                            <span>Eshani Das</span>    					
                        </li>
            
                        <li>
                            <strong>College:</strong>
                            <span>Pennsylvania State University</span>
                        </li>
                                
                        <li>
                            <strong>High School:</strong>
                            <span>Thomas Jefferson High School for Science and Technology</span>
                        </li>

                        <li>
                            <strong>Hometown:</strong>
                            <span>Fairfax, VA</span>
                        </li>

                        <li>
                            <strong>Age:</strong>
                            <span>19 Years</span>
                        </li>
                    </ul>
                </div>
        	</div>
        	<div class="right">
                <div class='about-text' >
                    <p>
                    I am a Schreyer Honors student in my second year at Pennsylvania State University, pursuing a major in Computer Science and a minor in Economics. I have been studying computer science since my freshman year of high school, and am comfortable coding in Java, Python, React, Node.js, C++, HTML, CSS, Javascript, SQL, and AWS. When I'm not in classes, I m often involved in research or independent coding projects. I've also done software development, artificial intelligence, and data analytics internships with Synaptein Solutions, the U.S. Department of Education, Xcalibur and PyCube. I am passionate about promoting women in STEM and have been a CS instructor with Girls Who Code since my sophomore year of high school and more recently a math instructor with Fairfax Collegiate.            
                    <p>
                    I'm involved in a variety of extracurriculars on campus, including Entrepreneurship &
                    Innovation Club, Society of Women Engineers, Engineering Leadership Society, South Asian Student Association, and Club Cross Country. In the rare moments I'm free, I enjoy reading, writing, baking, and traveling.</p>
                    </p>
                </div>
        
                <div class='about-btns' >
                    <a target="_blank" href={resume} class='btn-custom btn-color' download='Eshani Das Resume'> Download Resume</a>
                </div>
            </div>
        </div>
  )
}

export default Aboutme;
import React from 'react';
import './Intro.css'
import bg from '../../assets/image.png'
import btnImg from '../../assets/hireme.png'
import {Link} from 'react-scroll'

const Intro = () => {
  return (
    <section id='intro'>
        <div className='introContent'>
            <span className='hello'>Hello,</span>
            <span className='introText'>I'm <span className='introName'>Eshani</span>!</span>
            <p className='introPara'>Student | Data Analyst | Researcher | Founder</p>
            <Link><button className='btn'>DISCOVER MORE</button></Link>
        </div>
        <img src={bg} className='bg'/>
    </section>
  )
}

export default Intro;
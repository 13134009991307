import Navbar from './components/Navbar/Navbar'
import Intro from './components/Intro/Intro'
import Skills from './components/Skills/Skills'
import Aboutme from './components/Aboutme/Aboutme';

function App() {
  return (
    <div className="App">
      <Navbar/>
      <Intro/>
      <Aboutme/>
    </div>
  );
}

export default App;
